import React from 'react';
import './FloatingLogo.css';

function FloatingLogo() {
    const floatingLogoContainerStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.9)'
    };

    const floatingLogoStyle = {
        width: 400,
        height: 'auto',
        animation: 'float 3s ease-in-out infinite'
    };

    return (
        <div style={floatingLogoContainerStyle}>
            <img src={`${process.env.PUBLIC_URL}/Dinelist.png`} alt="Logo" style={floatingLogoStyle} />
        </div>
    );
}

export default FloatingLogo;
