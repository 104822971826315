import React, { lazy, Suspense, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { AuthProvider, AuthContext } from './AuthContext';
import PrivateRoute from './PrivateRoute';
import FloatingLogo from './Components/FloatingLogo';

// Lazy load the components
const Base = lazy(() => import('./Base'));
const Login = lazy(() => import('./Pages/Login'));
const Register = lazy(() => import('./Pages/Register'));
const Populate = lazy(() => import('./Pages/Populate'));
// Add other components as required

function App() {
  const { isCheckingAuth } = useContext(AuthContext);

  if (isCheckingAuth) {
    return <FloatingLogo />;
  }
  return (
    <AuthProvider>
      <Router>
        <Suspense fallback={<FloatingLogo />}>
          <Routes>
            <Route path="/*" element={
              <PrivateRoute>
                <Base />
              </PrivateRoute>
            } />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/populate" element={<Populate />} />
          </Routes>
        </Suspense>
      </Router>
    </AuthProvider>
  );
}

export default App;



{/* Uncomment and apply lazy loading to these routes as well
            <Route path="/oneforall" element={<PrivateRoute><OneForAll /></PrivateRoute>} />
            <Route path="/page" element={<PrivateRoute><Restaurantpage /></PrivateRoute>} />
            */}